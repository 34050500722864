import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "q-ml-xs" }
const _hoisted_2 = {
  key: 1,
  class: "subject-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_SubjectItem = _resolveComponent("SubjectItem")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, {
    bordered: "",
    separator: "",
    id: "wrapper-subjects"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects, (subject, index) => {
        return (_openBlock(), _createBlock(_component_q_item, {
          class: "column",
          key: index
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              (subject?.hasOwnProperty('subjects'))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    push: "",
                    size: "sm",
                    icon: subject.child ? "expand_more" : "expand_less",
                    type: "reset",
                    color: "white",
                    "text-color": "standard",
                    class: "q-mr-sm",
                    onClick: ($event: any) => (_ctx.toggleExpand(index))
                  }, null, 8, ["icon", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_icon, {
                name: "explore",
                size: "20px"
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(subject.area), 1),
              (subject?.hasOwnProperty("subjects") && subject?.child === 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subject.subjects, (s, subjectItemOrder) => {
                      return (_openBlock(), _createBlock(_component_SubjectItem, {
                        key: s.code_subject,
                        subject: s,
                        subjectOrder: index,
                        subjectItemOrder: subjectItemOrder
                      }, null, 8, ["subject", "subjectOrder", "subjectItemOrder"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}