import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-mb-none" }
const _hoisted_2 = {
  key: 0,
  class: "buildings-list q-mt-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_Addresses = _resolveComponent("Addresses")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("p", _hoisted_1, [
      (_ctx.district?.hasOwnProperty("addresses"))
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            size: "sm",
            icon: _ctx.district?.child ? "add" : "remove",
            type: "reset",
            color: "white",
            "text-color": "standard",
            class: "q-mx-sm q-mb-sm"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_icon, {
        name: "near_me",
        size: "20px"
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.district?.district), 1)
    ]),
    (_ctx.district?.hasOwnProperty('addresses') && _ctx.district.child === 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.district.addresses, (address, index) => {
            return (_openBlock(), _createBlock(_component_Addresses, {
              key: index,
              address: address,
              addressOrder: index,
              cityOrder: _ctx.cityOrder,
              districtOrder: _ctx.districtOrder
            }, null, 8, ["address", "addressOrder", "cityOrder", "districtOrder"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}