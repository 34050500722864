import script from "./FacilityAbstractWidget.vue?vue&type=script&setup=true&lang=ts"
export * from "./FacilityAbstractWidget.vue?vue&type=script&setup=true&lang=ts"

import "./FacilityAbstractWidget.vue?vue&type=style&index=0&id=f00541ac&lang=css"

const __exports__ = script;

export default __exports__
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QIcon,QBreadcrumbsEl,QTooltip,QSeparator});
