
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
import BuildingItem from '@/components/facility/Building.vue';

export default defineComponent({
  name: 'AddresseItem',
  components: {
    BuildingItem,
  },
  props: {
    address: Object,
    addressOrder: Number,
    cityOrder: Number,
    districtOrder: Number,
  },
  setup(props) {
    const divisionStore = useDivisionStore();
    const { points, facilityNodesState, facility } = storeToRefs(divisionStore);

    const showAddBuildingModal = ref(false);
    const confirm = ref(false);
    const nameBuilding = ref('');
    const zipCode = ref('');

    const askPoints = (item: any) => {
      // facilityNodesState.value.selectedAddress = props.address?.child ? {
      //   name: props.address?.address,
      //   id_address: props.address?.id_address,
      //   tooltip: 'Адрес',
      // } : null;
      // facilityNodesState.value.selectedBuilding = null;
      // facilityNodesState.value.selectedPoint = null;
      // facilityNodesState.value.selectedSpace = null;

      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('address')) {
        divisionStore.toggleNode({
          level: 'address',
          c: props.cityOrder,
          a: props.addressOrder,
          d: props.districtOrder,
        });
      }
      divisionStore.getPoints({
        id_address: props.address?.id_address,
        id_building: 0,
        id_space: 0,
      });
    };
    const removeAddress = () => {
      divisionStore.delAddress({
        id_address: props.address?.id_address,
      });
    };

    const tryAddBuilding = () => {
      divisionStore.addBuilding({
        building: nameBuilding.value,
        zip: zipCode.value,
        id_address: props.address?.id_address,
      });
      showAddBuildingModal.value = false;
    };

    return {
      askPoints,
      points,
      facilityNodesState,
      showAddBuildingModal,
      confirm,
      removeAddress,
      nameBuilding,
      zipCode,
      tryAddBuilding,
    };
  },
});
