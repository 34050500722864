import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "q-ml-xs" }
const _hoisted_2 = {
  key: 1,
  class: "districts-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_Disctricts = _resolveComponent("Disctricts")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_ctx.facility)
    ? (_openBlock(), _createBlock(_component_q_list, {
        key: 0,
        bordered: "",
        separator: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facility, (item, cityOrder) => {
            return (_openBlock(), _createBlock(_component_q_item, {
              class: "column",
              key: item.subject_code
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createVNode(_component_q_btn, {
                    push: "",
                    size: "sm",
                    icon: item.child ? "expand_more" : "expand_less",
                    type: "reset",
                    color: "white",
                    "text-color": "standard",
                    class: "q-mr-sm",
                    onClick: ($event: any) => (_ctx.toggleExpand(cityOrder))
                  }, null, 8, ["icon", "onClick"]),
                  _createVNode(_component_q_icon, {
                    name: "location_city",
                    size: "20px"
                  }),
                  (item.n > 0)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 0,
                        color: "dark",
                        class: "inline-block"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "near_me",
                            size: "14px"
                          }),
                          _createTextVNode(" " + _toDisplayString(item.n), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(item.subjectRF), 1),
                  (item.child === 0)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.districts, (district, index) => {
                          return (_openBlock(), _createBlock(_component_Disctricts, {
                            key: index,
                            district: district,
                            districtOrder: index,
                            cityOrder: cityOrder
                          }, null, 8, ["district", "districtOrder", "cityOrder"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}