
import { defineComponent } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
import Addresses from '@/components/facility/Addresses.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'DistrictItem',
  components: {
    Addresses,
  },
  props: {
    district: Object,
    districtOrder: Number,
    cityOrder: Number,
  },

  setup(props) {
    const divisionStore = useDivisionStore();
    const { facilityNodesState, facility } = storeToRefs(divisionStore);
  //   const toggleExpand = () => {
  //     facilityNodesState.value.selectedDistrict = props.district?.child ? {
  //       name: props.district?.district,
  //       id_district: props.district?.id_district,
  //       tooltip: 'Район',
  //     } : null;
  //     facilityNodesState.value.selectedAddress = null;
  //     facilityNodesState.value.selectedBuilding = null;
  //     facilityNodesState.value.selectedPoint = null;
  //     facilityNodesState.value.selectedSpace = null;
  //     if (
  //       facility.value[props.cityOrder ?? 0].code_subject
  //       !== facilityNodesState.value.selectedSubject.code_subject
  //     ) {
  //       facilityNodesState.value.selectedSubject = {
  //         name: facility.value[props.cityOrder ?? 0].subjectRF,
  //         code_subject: facility.value[props.cityOrder ?? 0].code_subject,
  //         tooltip: 'Субъект',
  //       };
  //     }
  //     divisionStore.toggleNode({
  //       level: 'district',
  //       c: props.cityOrder,
  //       d: props.districtOrder,
  //     });
  //   };
  //   return { toggleExpand };
  },
});
