import { useOrgsStore } from '~store/orgs/orgsModule';
import {
  ActionParams,
  ActionType,
} from '~components/TableComponent/types';
import { useMainStore } from '@/store';
import { ActionTypes } from './actionTypes';
import { actions } from './actions';

export const actionHandler = async ({
  actionId, actionType, params,
}: {
  actionId: ActionTypes, actionType: ActionType, params: any,
  }): Promise<void> => {
  if (actionType === 'modal') {
    useMainStore().openModal(actionId, params);
    return;
  }
  if (actionType === 'event') {
    const result = await useMainStore().dispatchEvent(actionId, params);
    console.log(result);
  }
  if (actionType === 'custom') {
    console.log('custom');
    const action = actions[actionId];
    action(params);
  }
};
