
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';

export default defineComponent({
  name: 'SpaceItem',
  props: {
    addressID: Number,
    buildingID: Number,
    space: Object,
    buildingOrder: Number,
    addressOrder: Number,
    spaceOrder: Number,
    cityOrder: Number,
    districtOrder: Number,
  },
  setup(props) {
    const divisionStore = useDivisionStore();
    const { facilityNodesState, facility } = storeToRefs(divisionStore);

    const confirm = ref(false);
    const showAddPointModal = ref(false);
    const namePoint = ref(null);
    const floors = ref(null);
    const isHovered = ref(false);
    const pointID = ref<number | null>(null);
    const setFloorModal = ref(false);

    const askPoints = () => {
      // facilityNodesState.value.number_space = {
      //   name: props.space?.number_space,
      //   id_space: props.space?.id_space,
      //   tooltip: 'Помещение',
      // };

      divisionStore.getPoints({
        id_address: props.addressID,
        id_building: props.buildingID,
        id_space: props.space?.id_space,
      });
    };
    const removeSpace = () => {
      divisionStore.delSpace({
        id_space: props.space?.id_space,
      });
    };
    const onDrop = (e: any) => {
      pointID.value = +e.dataTransfer.getData('itemID');
      setFloorModal.value = true;
    };

    // const createPoint = async () => {
    //   if (floors.value && namePoint.value) {
    //     await divisionStore.addPoint({
    //       id_space: facilityNodesState.value.number_space.id_space,
    //       floor: floors,
    //       name_point: namePoint,
    //     });
    //   } else {
    //     alert('Нет названия поинта или этажности');
    //   }
    //   showAddPointModal.value = false;
    //   namePoint.value = null;
    //   floors.value = null;
    // };

    const bindPoint = () => {
      divisionStore.bindPointSpace({
        id_space: props.space?.id_space,
        floor: floors,
        id_point: pointID,
      });
      setFloorModal.value = false;
    };

    return {
      facilityNodesState,
      removeSpace,
      confirm,
      showAddPointModal,
      askPoints,
      floors,
      namePoint,
      isHovered,
      pointID,
      onDrop,
      setFloorModal,
      bindPoint,
    };
  },
});
