
import { defineComponent, onMounted } from 'vue';
import MainLayout from '@/components/widgets/MainLayout.vue';
import { useDivisionStore } from '~store/division/divisionModule';

export default defineComponent({
  components: {
    MainLayout,
  },
  setup() {
    const divisionStore = useDivisionStore();

    onMounted(() => {
      divisionStore.getFacility();
      divisionStore.getRF();
      divisionStore.getTypesDistrict();
      divisionStore.fakeFacility();
    });
  },
});
