/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api, { Sructure } from '~utils/Api';
import { useMainStore } from '~store/index';
import {
  NodesMap, Cabinet, Facility, SelectedNode, FacilityNodes,
} from './types';
import facilityMock from './facilityMock.json';

const api = new Api();

export const useDivisionStore = defineStore('division', {
  state: () => ({
    cabinetsList: {
      data: [] as Cabinet[],
      structure: {} as TableStructure,
    },
    facility: null as any,
    facilityMock: undefined as Facility[] | undefined,
    subjects: null as any,
    typesDistrict: null as any,
    childMap: [] as NodesMap,
    showSpace: null,
    facilityNodesState: {
      subjectRF: null as SelectedNode | null,
      district: null as SelectedNode | null,
      address: null as SelectedNode | null,
      building: null as SelectedNode | null,
      number_space: null as SelectedNode | null,
      point: null as SelectedNode | null,
    } as FacilityNodes,
    constantParams: useMainStore().constantParams,
    layout: [],
    points: null as any,
    address: null,
    editedPoint: null as any,
  }),
  getters: {
    getLayout: (state) => state.layout,
  },
  actions: {
    updateLayout(context: never) {
      this.layout = context;
    },
    async fakeFacility() {
      this.facilityMock = await facilityMock;
    },

    // TODO: РЕФАКТОРИТЬ ЭТО СРОЧНО
    toggleNode(context: {
      level: string;
      c?: string | number;
      d?: string | number;
      a?: string | number;
      b?: string | number;
      s?: string | number;
    }) {
      let path;
      switch (context.level) {
        case 'city':
          path = this.facility[context.c ?? 0];
          break;
        case 'district':
          path = this.facility[context.c ?? 0].districts[context.d ?? 0];
          break;
        case 'address':
          path = this.facility[context.c ?? 0].districts[context.d ?? 0].addresses[context.a ?? 0];
          break;
        case 'building':
          path = this.facility[context.c ?? 0]
            .districts[context.d ?? 0]
            .addresses[context.a ?? 0]
            .buildings[context.b ?? 0];
          break;
        case 'space':
          /* eslint-disable-next-line max-len */
          path = this.facility[context.c ?? 0]
            .districts[context.d ?? 0]
            .addresses[context.a ?? 0]
            .buildings[context.b ?? 0]
            .spaces[context.s ?? 0];
          break;
        default: break;
      }
      path.child = path.child === 1 ? 0 : 1;
      if (context.level === 'building' && path.child === 0) this.showSpace = null;

      const result:NodesMap = [];
      function checkDeepChild(arr: any[], pathToField: { [x: string]: any; child: any; }[] | null) {
        arr.forEach((i, indexArr) => {
          if (i.n > 0) {
            /* eslint-disable-next-line no-restricted-syntax */
            for (const [indexObject, [key, value]] of Object.entries(Object.entries(i))) {
              if (Array.isArray(value)) {
                if (pathToField) {
                  pathToField.push({
                    child: i.child,
                    [key]: [],
                  });
                  checkDeepChild(i[key], pathToField[indexArr][key]);
                } else {
                  result.push({
                    child: i.child,
                    [key]: [],
                  });
                  checkDeepChild(i[key], result[indexArr][key]);
                }
              }
            }
          }
        });
      }
      checkDeepChild(this.facility, null);
      this.childMap = result;
    },
    async getCabinetsList() {
      const params = {
        Name_event: 'GET:cabinets_list',
        ...this.constantParams,
        json_params: JSON.stringify({
          id_point: this.facilityNodesState.point?.id ?? 0,
          id_building: this.facilityNodesState.building?.id ?? 0,
        }),
      };
      const result = await api.get(params);
      if (!result.errorType) {
        // TODO: Тип для структуры
        // eslint-disable-next-line prefer-destructuring
        const body = result.body;
        // eslint-disable-next-line prefer-destructuring
        const body2 = JSON.parse(result.body2[0].structure);
        if (body) {
          this.cabinetsList = {
            data: body,
            structure: body2,
          };
        }
      }
    },

    async getFacility() {
      const params = {
        Name_event: 'GET:facility_new',
        ...this.constantParams,
      };
      const result = await api.get(params);
      if (!result.errorType) {
        const [body] = <any>result.body;
        this.facility = JSON.parse(body.facility);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const deepCollapseChild = (arr: any, pathToField: any, pathCurrent: any) => {
          arr.forEach((i: any, indexArr: any) => {
            for (const [indexObject, [key, value]] of Object.entries(Object.entries(i))) {
              if (Array.isArray(value)) {
                if (pathToField) {
                  pathCurrent.forEach((j: any, index: any) => {
                    // eslint-disable-next-line no-param-reassign
                    j.child = pathToField[index].child;
                  });
                  deepCollapseChild(i[key], pathToField[indexArr][key], pathCurrent[indexArr][key]);
                } else if (this.childMap.length) {
                  console.log(this.childMap);
                  // eslint-disable-next-line no-param-reassign
                  i.child = this.childMap[indexArr].child;
                  deepCollapseChild(
                    i[key],
                    this.childMap[indexArr][key],
                    this.facility[indexArr][key],
                  );
                }
              }
            }
          });
        };
        if (this.facility.length) {
          deepCollapseChild(this.facility, null, null);
        }
      }
    },

    async getRF() {
      const params = {
        Name_event: 'GET:RF',
        ...this.constantParams,
      };
      const result = await api.get(params);
      if (!result.errorType) {
        const [body] = <any>result.body;
        this.subjects = JSON.parse(body.js);
      }
    },

    async getTypesDistrict() {
      const params = {
        Name_event: 'GET:types_district',
        ...this.constantParams,
      };
      const result = await api.get(params);
      if (!result.errorType) {
        const body = <any>result.body;
        this.typesDistrict = body;
      }
    },

    commitPoints(result: {
        errorText?: string; errorType?: number; body: any; body2?: Sructure[];
      }) {
      const [body] = <any>result.body;
      const points = JSON.parse(body.js);
      this.points = points[0].points;
      this.address = points[0].id_address;
    },

    async getPoints(specifics: {id_address: any, id_building: any, id_space: any}) {
      const params = {
        Name_event: 'GET:points',
        id_address: specifics.id_address,
        id_building: specifics.id_building,
        id_space: specifics.id_space,
        ...this.constantParams,
      };
      const result = await api.get(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async addPoint(specifics: { id_space: any; floor: any; name_point: any; }): Promise<void> {
      const params = {
        Name_event: 'POST:point_add',
        id_space: specifics.id_space,
        floor: specifics.floor,
        name_point: specifics.name_point,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async changeFloorPoint(specifics: {
      id_point_nn_space: any; floor: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:point_change_floor',
        id_point_nn_space: specifics.id_point_nn_space,
        floor: specifics.floor,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async changeNamePoint(specifics: {
      id_point: any; name_point: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:point_change_name',
        id_point: specifics.id_point,
        name_point: specifics.name_point,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async bindPointSpace(specifics: {
      id_point: any; id_space: any; floor: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:point_bind_space',
        id_space: specifics.id_space,
        id_point: specifics.id_point,
        floor: specifics.floor,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async unbindPointSpace(specifics: {
      id_point_nn_space: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:point_unbind_space',
        id_point_nn_space: specifics.id_point_nn_space,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.commitPoints(<any>result);
      }
    },

    async addAddress(specifics: {
      settlement: any;
      id_district: any;
      code_subject: any;
      address: any;
      id_point_nn_space: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:address_add',
        address: specifics.address,
        code_subject: specifics.code_subject,
        id_district: specifics.id_district,
        settlement: specifics.settlement,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.getFacility();
        this.getRF();
      }
    },

    async addBuilding(specifics: {
      building: any;
      zip: any;
      id_address: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:building_add',
        building: specifics.building,
        zip: specifics.zip,
        id_address: specifics.id_address,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.getFacility();
      }
    },

    async addDistrict(specifics: {
      code_subject: any;
      type_district: any;
      district: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:district_add',
        code_subject: specifics.code_subject,
        type_district: specifics.type_district,
        district: specifics.district,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.getRF();
      }
    },

    async addSpace(specifics: {
      n_floors: any;
      number_space: any;
      id_building: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:space_add',
        n_floors: specifics.n_floors,
        number_space: specifics.number_space,
        id_building: specifics.id_building,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        this.getFacility();
      }
    },

    async delPoint(specifics: {
      id_point: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:point_del',
        id_point: specifics.id_point,
        ...this.constantParams,
      };
      const result = await api.post(params);
      if (!result.errorType) {
        // eslint-disable-next-line no-prototype-builtins
        if (result?.body?.[0].hasOwnProperty('js')) {
          this.getPoints({
            id_address: this.facilityNodesState.address?.id,
            id_building: 0,
            id_space: 0,
          });
        } else {
          // eslint-disable-next-line no-alert
          alert(result?.body?.[0].error_text);
        }
      }
    },

    async delSpace(specifics: {
      id_space: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:space_del',
        id_space: specifics.id_space,
        ...this.constantParams,
      };
      try {
        const result = await api.post(params);
        if (!result.errorType) {
          // eslint-disable-next-line no-prototype-builtins
          if (!result?.body?.[0].hasOwnProperty('js')) {
            // eslint-disable-next-line no-alert
            alert(result?.body?.[0].error_text);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.getFacility();
      }
    },

    async delAddress(specifics: {
      id_address: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:address_del',
        id_address: specifics.id_address,
        ...this.constantParams,
      };
      try {
        const result = await api.post(params);
        if (!result.errorType) {
          // eslint-disable-next-line no-prototype-builtins
          if (!result?.body?.[0].hasOwnProperty('js')) {
            // eslint-disable-next-line no-alert
            alert(result?.body?.[0].error_text);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.getFacility();
      }
    },

    async delBuilding(specifics: {
      id_building: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:building_del',
        id_building: specifics.id_building,
        ...this.constantParams,
      };
      try {
        const result = await api.post(params);
        if (!result.errorType) {
          // eslint-disable-next-line no-prototype-builtins
          if (!result?.body?.[0].hasOwnProperty('js')) {
            // eslint-disable-next-line no-alert
            alert(result?.body?.[0].error_text);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.getFacility();
      }
    },

    async delDistrict(specifics: {
      id_district: any;
    }): Promise<void> {
      const params = {
        Name_event: 'POST:district_del',
        id_district: specifics.id_district,
        ...this.constantParams,
      };
      try {
        const result = await api.post(params);
        if (!result.errorType) {
          // eslint-disable-next-line no-prototype-builtins
          if (!result?.body?.[0].hasOwnProperty('js')) {
            // eslint-disable-next-line no-alert
            alert(result?.body?.[0].error_text);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.getFacility();
      }
    },
  },
});
