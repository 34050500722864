<template>
  <section>
    <q-btn-dropdown
      size="sm"
      class='q-ma-md'
      color="primary"
      label="Впендюрить виджет"
    >
      <q-list>
        <q-item
          v-for="widget in widgets"
          :key="widget.name"
          clickable
          v-close-popup
          @click="addItem(widget.component)"
        >
          <q-item-section>
            <q-item-label>{{ widget.component }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <q-list bordered separator v-if="collapsedItems" class='row'>
      <q-item
        clickable
        v-ripple
        v-for="(item, index) in collapsedItems"
        :key="index"
        @drag="drag"
        @dragend="dragend"
        draggable="true"
        class='droppable-element'
      >
        <q-item-section>{{ item.i }}</q-item-section>
      </q-item>
    </q-list>

        <div id="content">
            <grid-layout
                ref="gridLayout"
                @layout-updated="layoutUpdatedEvent"
                :value="layout"
                :col-num="12"
                :row-height="30"
                :is-draggable="draggable"
                :is-resizable="resizable"
                :is-responsive="responsive"
                :vertical-compact="true"
                :use-css-transforms="true"
            >
                <grid-item
                    :id="item.i"
                    v-for="(item, i) in layout"
                    :key="item.i"
                    :ref="el => (child[i] = el)"
                    drag-allow-from=".vue-draggable-handle"
                    drag-ignore-from=".no-drag"
                    :min-w="2"
                    :static="item.static"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                >
                        <div class="text">
                            <p class="action-bar">
                                <q-badge
                                    class="vue-draggable-handle"
                                    color="white"
                                    text-color="black"
                                >
                                  <q-icon name="grain" />
                                </q-badge>

                                <q-badge
                                    @click="toggleStatic(item.i)"
                                    class="vue-anchore"
                                    :color='item.static ? "black" : "white"'
                                    :text-color="item.static ? 'white' : 'black'"
                                >
                                  <q-icon name="anchor" />
                                </q-badge>

                                <q-badge
                                    @click="collapseItem(item)"
                                    class="vue-collapse"
                                    color='orange'
                                >
                                  <q-icon name="remove" />
                                </q-badge>

                                <q-badge
                                    @click="removeItem(item.i)"
                                    class="vue-remove"
                                    color='negative'
                                >
                                  <q-icon name="close" />
                                </q-badge>

                                <span class="widgetTitle">{{item.i}}</span>
                            </p>

                            <div class="no-drag">
                              <slot>
                                <component v-if="item.i !== 'drop'" :is="item.i" />
                              </slot>
                            </div>
                        </div>
                    </grid-item>
                </grid-layout>
            </div>
  </section>
</template>

<script>
import {
  defineComponent, nextTick, onMounted, reactive, ref, watch, onBeforeUpdate,
} from 'vue';
import { storeToRefs } from 'pinia';

import VueGridLayout from 'vue-grid-layout';
import { useDivisionStore } from '~store/division/divisionModule';
import Facility from '@/components/facility/FacilityWidget.vue';
import Subjects from '@/components/facility/SubjectsWidget.vue';
import CabinetsList from '@/views/CabinetsView.vue';
import FacilityAbstractWidget from '@/components/facility/FacilityAbstractWidget.vue';

export default defineComponent({
  components: {
    //  eslint-disable-next-line quote-props
    // 'facility': Facility,
    'cabinets-list': CabinetsList,
    //  eslint-disable-next-line quote-props
    'subjects': Subjects,
    'facility-abstract': FacilityAbstractWidget,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  setup() {
    const widgets = [
      // { component: 'facility' },
      { component: 'subjects' },
      { component: 'cabinets-list' },
      { component: 'facility-abstract' },
    ];

    const divisionStore = useDivisionStore();

    const { layout } = storeToRefs(divisionStore);
    const gridLayout = ref(null);
    const child = ref([]);
    const mouseXY = reactive({ x: null, y: null });
    const DragPos = reactive({
      x: null, y: null, w: 1, h: 1, i: null, static: false,
    });

    const collapsedItems = reactive([]);
    const draggable = ref(true);
    const resizable = ref(true);
    const responsive = ref(true);
    const colNum = 12;

    onBeforeUpdate(() => {
      child.value = [];
    });

    onMounted(() => {
      console.log(layout.value);
      document.addEventListener('dragover', (e) => {
        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      }, false);
    });

    const layoutUpdatedEvent = (newLayout) => {
      divisionStore.updateLayout(newLayout);
    };

    const isMobile = window.matchMedia('only screen and (max-width: 480px)').matches;

    const getYPosition = (name) => {
      let y = 0;
      layout.value.forEach((i) => {
        if (i.x === 0 && i.i !== name) {
          y = i.h;
        }
      });
      return y;
    };

    const addItem = async (componentName) => {
      if (layout.value.map((item) => item.i).indexOf(componentName) !== -1) return;
      let xLastEl;
      const item = layout.value[layout.value.length - 1];

      if (layout.value.length > 0) {
        console.log(item);
        xLastEl = item.x + item.w;
      }
      layout.value.push({
        x: xLastEl || 0,
        y: 0,
        w: 5,
        h: 4,
        i: componentName,
      });
      await nextTick();
      const el = document.getElementById(`wrapper-${componentName}`);
      const grid = document.getElementById('content');
      const addedItem = layout.value[layout.value.length - 1];
      addedItem.h = Math.ceil((el.clientHeight < 60 ? 60 : el.clientHeight) / 30);
      if (isMobile) {
        addedItem.w = 12;
      } else {
        addedItem.w = Math.ceil(el.clientWidth / ((grid.clientWidth - 5) / 12));
        if (xLastEl && (addedItem.w + xLastEl > 12)) {
          addedItem.x = 0;
        }
      }
      addedItem.y = getYPosition(addedItem.i);
    };

    const removeItem = (val) => {
      const index = layout.value.map((item) => item.i).indexOf(val);
      layout.value.splice(index, 1);
    };

    const toggleStatic = (val) => {
      const index = layout.value.map((item) => item.i).indexOf(val);
      layout.value[index].static = !layout.value[index].static;
    };

    const collapseItem = (val) => {
      collapsedItems.push(val);
      removeItem(val.i);
    };

    const drag = async (e) => {
      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right))
        && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (layout.value.findIndex((item) => item.i === 'drop')) === -1) {
        const index = collapsedItems.findIndex((item) => item.i === e.target.textContent);
        layout.value.push({
          x: (layout.value.length * 2) % (colNum || 12),
          y: layout.value.length + (colNum || 12),
          w: collapsedItems[index].w,
          h: collapsedItems[index].h,
          i: 'drop',
        });
      }
      const index = layout.value.findIndex((item) => item.i === 'drop');
      if (index !== -1) {
        try {
          await nextTick();
          child.value[index].$el.style.display = 'none';
        } catch (error) { console.error(error); }
        const el = child.value[index];
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
        const new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
        if (mouseInGrid === true) {
          gridLayout.value.dragEvent(
            'dragstart',
            'drop',
            new_pos.x,
            new_pos.y,
            layout.value[index].h,
            layout.value[index].w,
          );
          DragPos.i = e.target.textContent;
          DragPos.x = layout.value[index].x;
          DragPos.y = layout.value[index].y;
          DragPos.w = layout.value[index].w;
          DragPos.h = layout.value[index].h;
        } else {
          gridLayout.value.dragEvent(
            'dragend',
            'drop',
            new_pos.x,
            new_pos.y,
            layout.value[index].w,
            layout.value[index].h,
          );
          layout.value.splice(layout.value.findIndex((item) => item.i === 'drop'), 1);
        }
      }
    };

    const dragend = (e) => {
      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right))
      && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        try {
          gridLayout.value.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
        } catch (err) { console.error(err); }
        layout.value.splice(layout.value.findIndex((item) => item.i === 'drop'), 1);
        layout.value.push({
          x: DragPos.x,
          y: DragPos.y,
          w: DragPos.w,
          h: DragPos.h,
          i: DragPos.i,
        });
        gridLayout.value.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
        child.value[child.value.length - 1].$el.style.display = 'block';
        //  eslint-disable-next-line arrow-body-style
        collapsedItems.splice(collapsedItems.findIndex((item) => {
          return item.i === e.target.textContent;
        }), 1);
      }
    };

    return {
      layout,
      mouseXY,
      DragPos,
      widgets,
      collapsedItems,
      draggable,
      resizable,
      responsive,
      addItem,
      removeItem,
      toggleStatic,
      collapseItem,
      drag,
      dragend,
      layoutUpdatedEvent,
      gridLayout,
      child,
    };
  },
});

</script>

<style>
.vue-resizable-handle {
  z-index: 100;
}
</style>

<style scoped>
.vue-grid-layout::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 12) 40px;
    background-image: linear-gradient(
        to right,
        lightgrey 1px,
        transparent 1px
    ),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    /*height: 100%;*/
    /*width: 100%;*/
    position: absolute;
    background-repeat: repeat;
    margin:5px;
}
.vue-grid-layout {
    background: #eee;
    min-height: 80vh;
}
.vue-grid-item {
  border-radius: 0.25rem;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.vue-grid-item .no-drag {
    height: 95%;
    width: 100%;
    background-color: white;
}

.no-drag {
    font-size: 14px;
    text-align: left;
    overflow: scroll;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

.vue-draggable-handle {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    /* cursor: move; */
    font-size: 12px;
    width: fit-content;
}
.vue-remove {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    margin: 5px;
    font-size: 12px;
}
.vue-anchore {
    font-size: 12px;
    position: absolute;
    left: 35px;
    top: 0;
    margin: 5px 0;
    cursor: pointer;
}
.vue-collapse {
    position: absolute;
    right: 35px;
    top: 0;
    margin: 5px 0;
    font-size: 12px;
    cursor: pointer;
}
.droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    /* border: 1px solid black; */
    margin: 0 10px;
    padding: 10px;
}
/* .vue-draggable-dragging {
  cursor:
} */

.action-bar {
    height: 30px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}
.widgetTitle {
    font-size: 16px;
    margin-top: 3px;
}
</style>
