
import { storeToRefs } from 'pinia';
import { defineComponent, ref, onMounted } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
import Disctricts from '@/components/facility/Disctricts.vue';

export default defineComponent({
  components: {
    Disctricts,
  },
  setup() {
    const divisionStore = useDivisionStore();
    const { facility } = storeToRefs(divisionStore);

    const toggleExpand = (order: number) => {
      divisionStore.toggleNode({
        level: 'city',
        c: order,
      });
    };

    return { facility, toggleExpand };
  },
});
