
import { storeToRefs } from 'pinia';
import { defineComponent, ref, onMounted } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
import SubjectItem from '@/components/facility/Disctricts.vue';

export default defineComponent({
  components: {
    SubjectItem,
  },
  setup() {
    const divisionStore = useDivisionStore();
    const { subjects } = storeToRefs(divisionStore);

    const toggleExpand = (i: number) => {
      divisionStore.toggleNode({
        level: 'subject',
        s: i,
      });
    };

    return { subjects, toggleExpand };
  },
});
