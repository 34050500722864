
import { storeToRefs } from 'pinia';
import { defineComponent, ref, watch } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';

export default defineComponent({
  name: 'PointItem',
  props: {
    point: Object,
  },
  setup(props) {
    const divisionStore = useDivisionStore();
    const { editedPoint, facilityNodesState } = storeToRefs(divisionStore);

    const selectedSpace = ref<any|null>(null);
    const showFloors = ref(false);
    const selectedLevel = ref(null);
    const currentFloor = ref(null);
    const isDragging = ref(false);
    const namePointEditing = ref(false);
    const newNamePoint = ref(null);
    const isHovered = ref(false);

    watch(showFloors, (bool) => {
      if (!bool) {
        selectedLevel.value = null;
        currentFloor.value = null;
      }
    });
    const selectPoint = () => {
      facilityNodesState.value.point = {
        field_name: 'point',
        name: props.point?.name_point,
        id: props.point?.id_point,
        tooltip: 'Блок',
      };
      facilityNodesState.value.number_space = null;
    };
    const showFloorsModal = (item: any) => {
      selectedSpace.value = item;
      showFloors.value = true;
    };
    const initLevelEditing = (level: any) => {
      selectedLevel.value = level.id_point_nn_space;
      currentFloor.value = level.floor;
    };
    const unbindPoint = (level: any) => {
      divisionStore.unbindPointSpace({
        id_point_nn_space: level.id_point_nn_space,
      });
    };
    const saveNewLevel = () => {
      divisionStore.changeFloorPoint({
        id_point_nn_space: selectedLevel.value,
        floor: currentFloor.value,
      });
      selectedLevel.value = null;
      currentFloor.value = null;
    };
    const cancelEditing = () => {
      selectedLevel.value = null;
      currentFloor.value = null;
    };
    const startDrag = (e: any) => {
      console.log(e);
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('itemID', e.target.dataset.id);
      isDragging.value = true;
      console.log(e.target.dataset.id);
    };
    const prepareEditNamePoint = () => {
      editedPoint.value = props.point?.id_point;
      namePointEditing.value = true;
      newNamePoint.value = props.point?.name_point;
    };
    const cancelChanges = () => {
      editedPoint.value = null;
      namePointEditing.value = false;
    };
    const saveChanges = () => {
      divisionStore.changeNamePoint({
        id_point: props.point?.id_point,
        name_point: newNamePoint.value,
      });
      cancelChanges();
    };
    const onDropFromExternalWidget = (e: any) => {
      console.log(`ID_POINT___${props.point?.id_point}`);
      console.log(`ID_CABINET___${e.dataTransfer.getData('itemID')}`);
    };
    const removePoint = () => {
      divisionStore.delPoint({
        id_point: props.point?.id_point,
      });
    };

    return {
      facilityNodesState,
      startDrag,
      isDragging,
      onDropFromExternalWidget,
      isHovered,
      namePointEditing,
      editedPoint,
      newNamePoint,
      saveChanges,
      cancelChanges,
      prepareEditNamePoint,
      showFloorsModal,
      removePoint,
      selectedSpace,
      showFloors,
      selectedLevel,
      unbindPoint,
      initLevelEditing,
      currentFloor,
      saveNewLevel,
      cancelEditing,
      selectPoint,
    };
  },
});
