import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { createPinia } from 'pinia';
import TableComponent from '~components/TableComponent/TableComponent.vue';
import VueGridLayout from 'vue-grid-layout';
import App from './App.vue';
import router from './router';
import quasarUserOptions from './quasar-user-options';

const app = createApp(App);

app.use(createPinia())
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(VueGridLayout)
  .component('TableComponent', TableComponent)
  .mount('#app');
