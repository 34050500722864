import { RouteRecordRaw } from 'vue-router';
import LoginView from '~views/LoginView.vue';
import PageNotFound from '~views/PageNotFound.vue';
import CabinetsView from '~views/CabinetsView.vue';
import DashboardView from '~views/DashboardView.vue';
import WidgetsView from '~views/WidgetsView.vue';
import { getCookie } from '~utils/cookies';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (toCompare) next({ path: '/widgets' });
      else next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/login',
  },
  {
    path: '/cabinets',
    name: 'Cabinets',
    component: CabinetsView,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (to.meta.requiresAuth && (!toCompare)) next({ path: '/login' });
      else next();
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (to.meta.requiresAuth && (!toCompare)) next({ path: '/login' });
      else next();
    },
  },
  {
    path: '/widgets',
    name: 'Widgets',
    component: WidgetsView,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const store = useAuthStore();
      const toCompare = store.isAuth || (!!getCookie('id_login') && !!getCookie('TK'));
      if (to.meta.requiresAuth && (!toCompare)) next({ path: '/login' });
      else next();
    },
  },
];

export default routes;
