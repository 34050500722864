
import { storeToRefs } from 'pinia';
import {
  defineComponent, ref, onMounted, onDeactivated, onActivated, watch, nextTick,
  getCurrentInstance,
} from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';

export default defineComponent({
  name: 'LoginView',

  setup() {
    const divisionStore = useDivisionStore();
    const { cabinetsList, facilityNodesState } = storeToRefs(divisionStore);
    const isLoading = ref(false);
    const authStore = useAuthStore();
    const { isAuth } = storeToRefs(authStore);
    const rerender = ref(false);
    const instance = getCurrentInstance();
    onMounted(() => {
      // if (isAuth) divisionStore.getCabinetsList();
      // console.log(instance?.parent?.proxy);
      const parent: any = instance?.parent?.proxy;
      console.log(parent);
      // parent.autoSize();
    });
    watch(() => cabinetsList.value.data, async (val) => {
      rerender.value = !rerender.value;
      await nextTick();
      rerender.value = !rerender.value;
    });
    watch(() => facilityNodesState.value.building, (val) => {
      if (val) {
        facilityNodesState.value.point = null;
        divisionStore.getCabinetsList();
      }
    });
    watch(() => facilityNodesState.value.point, (val) => {
      if (val) {
        divisionStore.getCabinetsList();
      }
    });

    onActivated(() => {
      if (!cabinetsList.value.data.length && isAuth) {
        divisionStore.getCabinetsList();
      }
    });

    onDeactivated(() => {
      console.log('yo!');
    });

    // const objectCompare =
    // (baseObj: Record<string, unknown>, addObj: Record<string, unknown>) => {
    //   const struct: Record<string, unknown> = {};
    //   const keys = Object.keys(addObj);
    //   keys.forEach((key) => {
    //     if (!baseObj[key]) {
    //       struct[key] = addObj[key];
    //     } else {
    //       struct[key] = baseObj[key];
    //     }
    //   });
    //   return struct;
    // };

    // // eslint-disable-next-line max-len
    // const compareStructure =
    // (remoteStructure: Record<string, unknown>, localStructure: Record<string, unknown>):
    // Record<string, unknown> => objectCompare(remoteStructure, localStructure);
    return { cabinetsList, rerender, facilityNodesState };
  },
});
