import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useMainStore } from '@/store';
import { useOrgsStore } from '~store/orgs/orgsModule';
import { useAuthStore } from '~store/auth/authModule';
import App from '@/App.vue';

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

const orgsModule = useOrgsStore();
const mainStore = useMainStore();
const authModule = useAuthStore();

export const actions = {
  setCurrentOrg: (args: any) => useOrgsStore().setCurrentOrg(args),
  getFullOrgList: orgsModule.getFullOrgList,
  openModal: (id: string, args: any) => useMainStore().openModal(id, args),
  dispatchEvent: mainStore.dispatchEvent,
};
