import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import { useAuthStore } from '~store/auth/authModule';
import { Org } from './types';

const api = new Api();

export const useOrgsStore = defineStore('orgs', {
  state: () => ({
    fullOrgList: {
      data: [] as Org[],
      structure: {} as TableStructure,
    },
    constantParams: useMainStore().constantParams,
    currentOrg: {} as Org,
  }),
  getters: {

  },
  actions: {
    async getFullOrgList() {
      const params = {
        Name_event: 'GET:orgs_list_full',
        ...this.constantParams,
      };
      const result = await api.get(params);
      // if (!result.errorType) {
      //   const { body, structure } = result;
      //   if (body) {
      //     this.fullOrgList = {
      //       data: body,
      //       structure,
      //     };
      //   }
      // }
    },
    async setInitialOrg(): Promise<void> {
      const userId = useAuthStore().currentUser.id_login;
      const orgInStorage = localStorage.getItem(`medSafeCompany-${userId}`);
      if (!orgInStorage) {
        return;
      }
      let savedOrg: Org | null = null;

      try {
        savedOrg = JSON.parse(orgInStorage) as Org;
      } catch (error) {
        useMainStore().showMessage('Не удается загрузить организацию');
        return;
      }

      if (!savedOrg) {
        await this.getFullOrgList();
        const currentOrg = this.fullOrgList.data.length === 1
          ? this.fullOrgList.data[0]
          : null;
        if (currentOrg) {
          this.setCurrentOrg(currentOrg);
        }
        return;
      }
      this.setCurrentOrg(savedOrg);
    },
    setCurrentOrg(currentOrg: Org): void {
      const userId = useAuthStore().currentUser.id_login;
      if (currentOrg) {
        this.currentOrg = currentOrg;
        localStorage.setItem(`medSafeCompany-${userId}`, JSON.stringify(currentOrg));
      }
    },
  },
});
