
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
import { useDivisionStore } from '~store/division/divisionModule';
import Space from '@/components/facility/Space.vue';

export default defineComponent({
  name: 'BuildingItem',
  components: {
    Space,
  },
  props: {
    addressID: Number,
    building: Object,
    buildingOrder: Number,
    addressOrder: Number,
    cityOrder: Number,
    districtOrder: Number,
  },
  setup(props) {
    const divisionStore = useDivisionStore();
    const { facilityNodesState, facility } = storeToRefs(divisionStore);
    const showSpaceModal = ref(false);
    const confirm = ref(false);
    const numFloors = ref('');
    const numSpace = ref('');

    const askPoints = (item: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('building')) {
        divisionStore.toggleNode({
          level: 'building',
          c: props.cityOrder,
          a: props.addressOrder,
          b: props.buildingOrder,
          d: props.districtOrder,
        });
      }
      divisionStore.getPoints({
        id_address: props?.addressID ?? 0,
        id_building: props.building?.id_building ?? 0,
        id_space: 0,
      });
    };

    const removeBuilding = () => {
      divisionStore.delBuilding({
        id_building: props.building?.id_building,
      });
    };

    const tryAddSpace = () => {
      divisionStore.addSpace({
        n_floors: numFloors.value,
        number_space: numSpace.value,
        id_building: props.building?.id_building,
      });
      showSpaceModal.value = false;
    };

    return {
      askPoints,
      facilityNodesState,
      showSpaceModal,
      confirm,
      removeBuilding,
      numFloors,
      numSpace,
      tryAddSpace,
    };
  },
});
