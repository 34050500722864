
import { defineComponent } from 'vue';
import LoginForm from '@/components/login/LoginForm.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    LoginForm,
  },
  setup() {
    return { };
  },
});
