import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "./App.vue?vue&type=style&index=0&id=ad46fb4c&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerPie from 'quasar/src/components/spinner/QSpinnerPie.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QInnerLoading,QSpinnerPie,QDialog,QCard,QCardSection});
