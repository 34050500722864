export const NAME_APP = 'WEB_division';
export enum modules {
  webmanagers = 'WEB_managers',
  calculation = 'module_calculation',
  contacts = 'module_contacts',
  medAdmin = 'medAdmin',
  covid = 'Covid',
  division = 'division',
}
export const MESSAGE_DELAY = 3000;
